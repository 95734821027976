import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import i18n from "./i18n";
import "./scss/custom.scss";
import "./plugins/qcalendar";

import VueGtag from "vue-gtag";

const nombrePlataforma = "Plataforma de Atención al Vecino"; 

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_ID_ANALYTIC as string },
    appName: nombrePlataforma,
    pageTrackerTemplate(to: any) {
      let title = to.name;
      switch (title) {
      	case "home":
		title = "Inicio - ".concat(nombrePlataforma);
		break;
	case "login":
		title = "Login - ".concat(nombrePlataforma);
		break;
	case "register":
		title = "Registro - ".concat(nombrePlataforma);
		break;
	case "myrequests":
		title = "Mis solicitudes - ".concat(nombrePlataforma);
		break;
	case "encuesta":
		title = "Encuesta - ".concat(nombrePlataforma);
		break;
	case "newrequest":
		title = "Nueva Solicitud - ".concat(nombrePlataforma);
		break;
	case "solicitudPermisoCirculacion":
		title = "Solicitud Permiso Circulación - ".concat(nombrePlataforma);
		break;
	case "solicitudArbolado":
		title = "Solicitud mantención Arbolado - ".concat(nombrePlataforma);
		break;
	case "solicitudFeriaEmprendimiento":
		title = "Solicitud Inscripción Feria Emprendimiento - ".concat(nombrePlataforma);
		break;
	case "newrequestservice":
		title = "Nueva Solicitud - ".concat(nombrePlataforma);
		break;
	case "newdate":
		title = "Nueva Solicitud Agenda - ".concat(nombrePlataforma);
		break;
	case "requestdetails":
		title = "Ver Solicitud - ".concat(nombrePlataforma);
		break;
	case "myaccount":
		title = "Mi cuenta - ".concat(nombrePlataforma);
		break;
	case "forgottenpassword":
		title = "Recuperar password - ".concat(nombrePlataforma);
		break;
	case "resetpassword":
		title = "Recuperar password - ".concat(nombrePlataforma);
		break;
      }
      return {
        page_title: title,
        page_path: to.path
      };
    }
  },
  router
);

Vue.config.productionTip = false;
Vue.prototype.$servicioArbolado = Number(
  process.env.VUE_APP_SERVICIO_ARBOLADO as string
);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
