
import { Component, Vue } from "vue-property-decorator";
@Component
export default class TheNavbar extends Vue {
  private right: Boolean = false;
  private get tab(): String {
    return this.$router.currentRoute.name!;
  }
  private get userName(): String {
    return (
      this.$store.state.user.name +
      " " +
      this.$store.state.user.father_last_name
    );
  }

  private closeDrawer(route: string) {
    if (this.$router.currentRoute.name == route) {
      this.$router.go(0);
    }
    this.right = false;
  }

  private navAdmin(e: any) {
    e.preventDefault();
    window.location.replace("https://applareina.atencionalvecino.cl");
  }
  private get isUserLogged(): String {
    return this.$store.state.logged;
  }
  private logout(): void {
    this.$store.dispatch("logout");
    this.right = false;
  }

  private redirectToAdmin() {
    window.location.href = "https://applareina.atencionalvecino.cl/";
  }
}
