
import { Component, Vue } from "vue-property-decorator";
import TheNavbar from "@/components/global/TheNavbar.vue";
import TheFooter from "@/components/global/TheFooter.vue";
import store from "@/store";

@Component({
  components: {
    TheNavbar,
    TheFooter
  }
})
export default class App extends Vue {}
