"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
// Full config:  https://github.com/axios/axios#request-config
//axios.defaults.baseURL = process.env.VUE_APP_API_PATH || "";
//axios.defaults.headers.common["Authorization"] = store.state.logged? store.state.user.auth_token: null;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  baseURL: process.env.VUE_APP_API_PATH || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};
const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    if (store.state.logged) {
      if (Date.now() - Date.parse(store.state.user.created) > 1199999) {
        store.dispatch("clearCredentials");
        router.push({
          name: "login",
          query: {
            timeout: "true"
          }
        });
        return Promise.reject(error);
      }
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
